import axios from 'axios';
import authService from '../services/authService';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_LINK}`, // Perhatikan penggunaan backticks
});

instance.interceptors.request.use(
  (config) => {
    const authUser = authService.getAuthUser();
    if (authUser) {
      config.headers['authorization'] = `Bearer ${authUser.replace(/"/g, '')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('authUser');
      window.location.reload();
    } else {
      return Promise.reject(error.response);
    }
  },
);

const get = (url, params, config = {}) =>
  instance.get(url, { params, ...config });
   
const post = (url, data, config = {}) => instance.post(url, data, config);

const del = (url, config = {}) =>
  instance.delete(url, config);

const patch = (url, data, config = {}) =>
  instance.patch(url, data, config);

const methods = { get, post, del, patch };


export default methods;
