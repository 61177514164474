import React, {FC} from "react";

export interface AdminDashboardProps {
    className?: string;
}

const AdminDashboard: FC<AdminDashboardProps> = ({className = ""}) => {
    return (
        <div className={className} />
        
    )
}

export default AdminDashboard;