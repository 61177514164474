import React, {useState} from "react";

interface LikeSaveBtnsProps {
  slug: string; // Tambahkan slug sebagai prop
}

const LikeSaveBtns: React.FC<LikeSaveBtnsProps> = ({ slug }) => {

  const [isLiked, setIsLiked] = useState(false); // State to track if liked


  const handleShareClick = () => {
    const currentUrl = window.location.origin + "/experiences/details/" + slug;
    
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this experience",
          url: currentUrl,
        })
        .then(() => {
          console.log("Sharing successful");
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log("Share canceled by the user");
          } else {
            console.error("Error sharing:", error);
          }
        });
    } else {
      // Fallback untuk browser yang tidak mendukung navigator.share
      navigator.clipboard.writeText(currentUrl).then(() => {
        alert("Link copied to clipboard!");
      });
    }
  };

  const handleLikeClick = () => {
    setIsLiked(!isLiked); // Toggle liked state
  };
  

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span
          className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
          onClick={handleShareClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span>
        <span
          className={`py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer transition-colors duration-300 ${isLiked ? "text-red-500" : ""}`}
          onClick={handleLikeClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 transition-transform duration-300 ${isLiked ? "scale-125" : ""}`}
            fill={isLiked ? "red" : "none"} // Change fill to red if liked
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Like</span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
