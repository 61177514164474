import React, { FC } from "react";
import { CustomLink } from "data/types";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, className = "" }) => {

  const renderItem = (pageNumber: number) => {
    if (pageNumber === currentPage) {
      return (
        <span
          key={pageNumber}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pageNumber}
        </span>
      );
    }

    return (
      <button
        key={pageNumber}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={() => onPageChange(pageNumber)}
      >
        {pageNumber}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {[...Array(totalPages)].map((_, index) => renderItem(index + 1))}
    </nav>
  );
};

export default Pagination;
