import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import githubSvg from "images/Github.svg";

type Config = {
  isUsed: boolean;
  name: string;
  href: string;
  icon: string;
};

class Oauth {
  public config: Array<Config>;

  constructor() {
    this.config = this.loadConfig();
  }

  private loadConfig(): Array<Config> {
    return [
      {
        isUsed: true,
        name: "Continue with Facebook",
        href: this.getFacebookOauthUrl(),
        icon: facebookSvg,
      },
      {
        isUsed: false,
        name: "Continue with Twitter",
        href: this.getTwitterOauthUrl(),
        icon: twitterSvg,
      },
      {
        isUsed: true,
        name: "Continue with Google",
        href: this.getGoogleUrl(process.env.REACT_APP_ORIGIN as string),
        icon: googleSvg,
      },
      {
        isUsed: false,
        name: "Continue with Github",
        href: this.getGithubOauthUrl(process.env.REACT_APP_ORIGIN as string),
        icon: githubSvg,
      },
    ];
  }

  public addWwwToUrl(url: string): string {
    if (url?.indexOf("www.") === -1) {
      return url.replace(/^https?:\/\//, "$&www.");
    }
    return url;
  }

  public getGoogleUrl(from: string): string {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
    const options: any = {
      redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_CALLBACK,
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: [
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
      ].join(" "),
      state: from,
    };

    const qs = new URLSearchParams(options);
    const oauthUrl = `${rootUrl}?${qs.toString()}`;
    return oauthUrl;
  }

  public getTwitterOauthUrl(): string {
    const rootUrl = "https://twitter.com/i/oauth2/authorize";
    const options: any = {
      redirect_uri: this.addWwwToUrl(
        process.env.REACT_APP_TWITTER_OAUTH_CALLBACK as string
      ),
      client_id: process.env.REACT_APP_TWITTER_OAUTH_CLIENT_ID,
      state: "state",
      response_type: "code",
      code_challenge: "y_SfRG4BmOES02uqWeIkIgLQAlTBggyf_G7uKT51ku8",
      code_challenge_method: "S256",
      scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(
        " "
      ),
    };

    const qs = new URLSearchParams(options);
    const oauthUrl = `${rootUrl}?${qs.toString()}`;
    return oauthUrl;
  }

  public getFacebookOauthUrl(): string {
    const rootUrl = "https://www.facebook.com/v13.0/dialog/oauth";
    const options: any = {
      redirect_uri: process.env.REACT_APP_FACEBOOK_OAUTH_CALLBACK as string,
      client_id: process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID,
      scope: ["email", "public_profile"].join(" "),
      response_type: "code",
    };

    const qs = new URLSearchParams(options);
    const oauthUrl = `${rootUrl}?${qs.toString()}`;
    return oauthUrl;
  }

  public getGithubOauthUrl(from: string): string {
    const rootURl = "https://github.com/login/oauth/authorize";

    const options: any = {
      client_id: process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GITHUB_OAUTH_CALLBACK,
      scope: "user:email",
      state: from,
    };

    const qs = new URLSearchParams(options);
    return `${rootURl}?${qs.toString()}`;
  }
}

const helper = new Oauth();  

export default helper;
