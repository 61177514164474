import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";

interface CommentListingProps {
  className?: string;
  review: {
    user?: {
      name: string;
    };
    created_at: string;
    message: string;
    rating: number;
  };
}

const CommentListing: FC<CommentListingProps> = ({ className = "", review }) => {
  return (
    <div className={`nc-CommentListing flex space-x-4 ${className}`} data-nc-id="CommentListing">
      <div className="pt-0.5">
        <Avatar sizeClass="h-10 w-10 text-lg" radius="rounded-full" userName={review.user?.name || 'Anonymous'} />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{review.user?.name || 'Anonymous'}</span>
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {new Date(review.created_at).toLocaleDateString()}
            </span>
          </div>
          <div className="flex text-yellow-500">
            {Array.from({ length: review.rating }).map((_, idx) => (
              <StarIcon key={idx} className="w-4 h-4" />
            ))}
          </div>
        </div>
        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
          {review.message}
        </span>
      </div>
    </div>
  );
};

export default CommentListing;
