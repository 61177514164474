import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header/Header";
import { useLocation } from "react-router-dom";
import { PathName } from "routers/types";

export type SiteHeaders = "Header 1";

const SiteHeader = () => {
  // Membuat referensi ke elemen HTML untuk manajemen IntersectionObserver
  const anchorRef = React.useRef<HTMLDivElement>(null);

  // Mendefinisikan state header yang dipilih
  const [headerSelected, setHeaderSelected] = React.useState<SiteHeaders>("Header 1");

  // Mendefinisikan state apakah halaman berada di atas (scroll ke atas)
  const [isTopOfPage] = React.useState(window.pageYOffset < 5);

  // Mendapatkan lokasi (path) saat ini dari React Router
  const location = useLocation();

  // Efek yang berjalan saat lokasi (path) berubah
  useEffect(() => {
    // Jika lokasi saat ini adalah "/", atur header yang dipilih ke "Header 1"
    if (location.pathname === "/") {
      setHeaderSelected("Header 1");
    }
  }, [location.pathname]);

  // Fungsi untuk merender header berdasarkan pilihan yang dipilih
  const renderHeader = () => {
    // Inisialisasi kelas CSS header
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";

    // Mengecek apakah halaman berada dalam PAGES_HIDE_HEADER_BORDER (belum dijelaskan di kode)
    if ((location.pathname as PathName)) {
      headerClassName = isTopOfPage
        ? ""
        : "shadow-sm dark:border-b dark:border-neutral-700";
    }

    // Merender komponen Header dengan tipe navigasi "MainNav1"
    switch (headerSelected) {
      case "Header 1":
        return <Header className={headerClassName} navType="MainNav1" />;
      default:
        return <Header className={headerClassName} navType="MainNav1" />;
    }
  };

  // Komponen SiteHeader
  return (
    <>
        <Helmet>
          {/* Mengatur judul halaman */}
          <title>BaliGuided | Book your Bali Activities with us!</title>
        </Helmet>
      {/* Memanggil fungsi untuk merender header */}
      {renderHeader()}

      {/* Elemen yang digunakan untuk manajemen IntersectionObserver, umumnya tersembunyi */}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
