import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About BaliProGuide</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="About BaliProGuide."
          btnText=""
          subHeading="At BaliProGuide, we are passionate about helping people experience the magic of Bali. We believe that everyone deserves to have an unforgettable vacation, and we are committed to providing our customers with the best possible travel experience."
        />

        <SectionFounder />

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;