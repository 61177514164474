import React, { ReactNode, useState } from "react";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import SectionGridFilterCard from "containers/ListingExperiencesPage/SectionGridFilterCard";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";

interface DetailPagetLayoutProps {
  children: ReactNode;
  experience: any;
  currency: string;
  guests: number;
  convertedPrice: number;
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onGuestsChange: (guests: number) => void;
}

const DetailPagetLayout: React.FC<DetailPagetLayoutProps> = ({
  children,
  experience,
  currency,
  guests,
  convertedPrice,
  selectedDate,
  onDateChange,
  onGuestsChange,
}) => {

  
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  // Tambahkan state untuk selectedTime
  const [selectedTime, setSelectedTime] = useState<string>("");

  // Handler untuk perubahan waktu
  const onTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if (thisPathname?.includes("/listing-experiences-detail")) {
      return listingExperienceImageGallery;
    }

    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <SectionGridFilterCard       
            className="PageHome_s1"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>

      <MobileFooterSticky
        experience={experience}
        currency={currency}
        guests={guests}
        convertedPrice={convertedPrice} // Calculated price with guests data
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        onDateChange={onDateChange}
        onGuestsChange={onGuestsChange}
        onTimeChange={onTimeChange}
      />
    </div>
  );
};

export default DetailPagetLayout;
