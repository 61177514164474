import httpClient from '../utils/httpClient';

const reviewService = {
  createReview: async (experienceId, rating, message, userId) => {
    console.log("Sending the following data:");
    console.log("experience_id:", experienceId);
    console.log("rating:", rating);
    console.log("message:", message);
    console.log("user_id:", userId);
    
    try {
      const response = await httpClient.post(`/reviews`, {
        experience_id: experienceId,
        rating,
        message,
        user_id: userId,
      });
      return response.data;
    } catch (error) {
      console.error("Failed to create review:", error);
      throw error;
    }
  },
  
  

  async getReviews(experienceId) {
    try {
      const response = await httpClient.get(`/reviews/${experienceId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get reviews:', error);
      throw error;
    }
  },

  async getReviewById(reviewId) {
    try {
      const response = await httpClient.get(`/reviews/${reviewId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch review:', error);
      throw error;
    }
  },

  async updateReview(reviewId, reviewData) {
    try {
      const response = await httpClient.put(`/reviews/${reviewId}`, reviewData);
      return response.data;
    } catch (error) {
      console.error('Failed to update review:', error);
      throw error;
    }
  },

  async deleteReview(reviewId) {
    try {
      const response = await httpClient.delete(`/reviews/${reviewId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete review:', error);
      throw error;
    }
  },
};

export default reviewService;
