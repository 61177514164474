import React from "react";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Mengganti swiper-bundle.min.css
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from 'swiper/modules'; // Perhatikan perubahan ini

interface ImageGalleryModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  images: string[];
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  isOpen,
  onRequestClose,
  images,
}) => {
  console.log('Images passed to modal:', images); // Debugging line
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Gallery"
      appElement={document.getElementById("root") || undefined}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-40"
    >
      <div className="relative bg-white rounded-lg shadow-xl w-full max-w-3xl p-6">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        <Swiper
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]} // Ensure modules are correctly set up
          className="h-96"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="object-cover w-full h-full rounded-lg"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  );
};


export default ImageGalleryModal;
