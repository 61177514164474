import React, { FC, useEffect, useState } from "react";
import { Alert } from "shared/Peringatan/Peringatan";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import authService from "services/authService";
import { useNavigate, useSearchParams } from "react-router-dom";

const ForgotPassword: FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<
    "success" | "error" | "default" | "warning" | "info"
  >("default");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const processOnPageLoad = async () => {
    const token = searchParams.get("token");
    if (!token) navigate("/login");

    try {
      await authService.checkForgotPasswordToken(token);
    } catch (error) {
      navigate("/login");
    }
  };

  useEffect(() => {
    processOnPageLoad();
  }, []);

  const handleForgotPassword = async () => {
    try {
      const response = await authService.solveForgottenPassword({
        token: searchParams.get("token") as string,
        new_password: newPassword,
        confirm_password: confirmPassword,
      })
      console.log(response);
      navigate("/login");
      setMessage(response.message);
      setTypeMessage("success");
    } catch (error: any) {
      setMessage(error.message);
      setTypeMessage("error");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center my-14">
      <div className="text-center">
        <h2 className="text-3xl font-semibold">
          Update Your Forgotten Password
        </h2>
      </div>
      <div className="mt-20 w-full md:w-[500px] text-center">
        <p className="mb-2">
          You can access this page only once, prior to updating the password.
        </p>
        <Input
          placeholder="your new password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          placeholder="your new password confirmation"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="mt-4"
        />
      </div>
      <div className="mt-8">
        <ButtonPrimary onClick={handleForgotPassword} type="button">
          Resend
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default ForgotPassword;
