import http from '../utils/httpClient';

const login = async (data) => {
  try {
    const response = await http.post('/auth/login', data);
    const authUser = response.data.payload.token;
    localStorage.setItem('authUser', JSON.stringify(authUser));
    return response.data;
  } catch (error) {

    console.error('Login error:', error);
    throw new Error(error.data.message);
  }
};

const register = async (data) => {
  try {
    const response = await http.post('/auth/register', data);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw new Error(error.data.message);
  }
};

const logout = () => {
  localStorage.removeItem('authUser');
};

const getAuthUser = () => {
  return localStorage.getItem('authUser')
}

const updatePassword = async (data) => {
  try {
    const response = await http.patch('/auth/password', data);
    return response.data;
  } catch (error) {
    console.error('Update password error:', error);
    throw new Error(error.data.message);
  }
}

const resendVerificationEmail = async (data) => {
  try {
    const response = await http.post('/auth/email/verification/resend', data);
    return response.data;
  } catch (error) {
    console.error('Resend verification email error:', error);
    throw new Error(error.data.message);
  }
}

const sendUpdateEmailVerification = async (data) => {
  try {
    const response = await http.patch('/auth/verify/update', data);
    return response.data;
  } catch (error) {
    console.error('Send update email verification error:', error);
    throw new Error(error.data.message);
  }
}

const getDecodedToken = async (token) => {
  try {
    const response = await http.get('/auth/token?token=' + token);
    return response.data.payload;
  } catch (error) {
    console.log('Get decoded token error:', error);
    throw new Error(error.data.message);
  }

}
const sendEmailForgotPassword = async (data) => {
  try {
    const response = await http.post('/auth/forgot-password/send-email', data);
    return response.data.payload;
  } catch (error) {
    console.log('Send email forgot password:', error);
    throw new Error(error.data.message);
  }
}

const checkForgotPasswordToken = async (token) => {
  try {
    const response = await http.get('/auth/forgot-password/token/check?token=' + token);
    return response.data.payload;
  } catch (error) {
    console.log('Solve forgot password token check:', error);
    throw new Error(error.data.message);
  }
}

const solveForgottenPassword = async (data) => {
  try {
    const response = await http.patch('/auth/forgot-password/solve', data);
    return response.data.payload;
  } catch (error) {
    console.log('Solve forgot password:', error);
    throw new Error(error.data.message);
  }
}

const changeEmail = async (data) => {
  try {
    const response = await http.patch('/auth/email/update/send-verification', data);
    return response.data;
  } catch (error) {
    console.error('Change email error:', error);
    throw new Error(error.data.message);
  }
}
  

const authService = {
  login,
  register,
  logout,
  getAuthUser,
  updatePassword,
  resendVerificationEmail,
  sendUpdateEmailVerification,
  getDecodedToken, 
  sendEmailForgotPassword,
  checkForgotPasswordToken,
  solveForgottenPassword,
  changeEmail
};

export default authService;
