import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import SectionHeroArchivePage from "components/SectionHeroExperience/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCardSearch";
import { Helmet } from "react-helmet";
import SectionGridFilterCardSearch from "./SectionGridFilterCardSearch";

export interface ListingExperiencesPageSearchProps {
className?: string;
}

const ListingExperiencesPageSearch: FC<ListingExperiencesPageSearchProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingExperiencesPageSearch relative overflow-hidden ${className}`}
      data-nc-id="ListingExperiencesPageSearch"
    >
      <Helmet>
        <title>Bali Activites and Tours Online Booking</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Experiences"
          currentTab="Experiences"
          listingType={
            <>
              <i className="text-2xl las la-umbrella-beach"></i>
              <span className="ml-2.5">100+ experiences</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        <SectionGridFilterCardSearch className="pb-24 lg:pb-28" />

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ListingExperiencesPageSearch;
