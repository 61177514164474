import React, { FC } from "react";
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";

import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}


const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {

  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/experiences');
  };

  return (

    
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            Day Tours, Activities and Stays in Bali.
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Discover Bali's magic with BaliProGuide, the island's premier, legal, and trusted tour company for unforgettable day tours, thrilling rafting adventures, and much more. Our expert guides and tailored experiences ensure you explore Bali's wonders with trust and authenticity, creating lasting memories. 
          <br />
          <br />
          Join us in uncovering the heart of this tropical paradise, and let BaliProGuide be your gateway to Bali's enchantment.
          </span>
          <ButtonPrimary onClick={handleClick}>Book Now</ButtonPrimary>
          </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
