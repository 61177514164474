import React, { FC, useState } from "react";
import { Alert } from "shared/Peringatan/Peringatan";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import authService from "services/authService";

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<
    "success" | "error" | "default" | "warning" | "info"
  >("default");

  const handleForgotPassword = async () => {
    try {
      const response = await authService.sendEmailForgotPassword({ email });
      console.log(response);
      setMessage(response.message);
      setTypeMessage("success");
    } catch (error: any) {
      setMessage(error.message);
      setTypeMessage("error");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center my-14">
      <div className="text-center">
        <h2 className="text-3xl font-semibold">
          Send Forgot Password To Your Email
        </h2>
      </div>
      <div className="mt-20 w-full md:w-[500px] text-center">
        <p className="mb-2">Send email forgot password</p>
        <Input
          placeholder="your email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-8">
        <ButtonPrimary onClick={handleForgotPassword} type="button">
          Resend
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default ForgotPassword;
