import React, { FC, useEffect, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/CardTour/ExperiencesCard";
import experienceService from "../../services/experienceService";
import { ExperiencesDataType } from "data/types";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [experiences, setExperiences] = useState<ExperiencesDataType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchExperiences = async (page = 1) => {
    try {
      const response = await experienceService.getExperiencesPagination(page);
      if (response) {
        setExperiences(response.payload);
        setTotalPages(response.meta.totalPages);
      } else {
        console.error('No payload found:', response);
      }
    } catch (error) {
      console.error('Failed to fetch experiences:', error);
    }
  };

  useEffect(() => {
    fetchExperiences(currentPage);
  }, [currentPage]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Experiences in Bali"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            100+ Experiences
            <span className="mx-2">·</span>
            Start From 10$
            <span className="mx-2">·</span>Solo Traveler Welcomed
          </span>
        }
      />
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {experiences.map((experience) => (
          <ExperiencesCard key={experience.id} data={experience} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
    <Pagination 
    currentPage={currentPage} 
    totalPages={totalPages} 
    onPageChange={(page) => setCurrentPage(page)} 
    />
    </div>  
    </div>
  );
};

export default SectionGridFilterCard;
