import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import {
  UserIcon,
  CheckCircleIcon,
  ArrowsRightLeftIcon,
  CalendarIcon,
  ClockIcon,
  MapIcon
} from "@heroicons/react/24/solid";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";

const SearchResultsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const storedData = localStorage.getItem('airportTransferData');
  const initialData = storedData ? JSON.parse(storedData) : {};

  const pickupAddress = new URLSearchParams(location.search).get("pickupAddress") || initialData.pickupAddress || "";
  const dropoffAddress = new URLSearchParams(location.search).get("dropoffAddress") || initialData.dropoffAddress || "";
  const date = new URLSearchParams(location.search).get("date") || initialData.date || "";
  const time = new URLSearchParams(location.search).get("time") || initialData.time || "";
  const roundTrip = new URLSearchParams(location.search).get("roundTrip") === "true" || initialData.roundTrip || false;
  const adults = new URLSearchParams(location.search).get("adults") || initialData.adults || "1";
  const distance = new URLSearchParams(location.search).get("distance") || initialData.distance || "";
  const seat = new URLSearchParams(location.search).get("seat") || initialData.seat || "Standard";
  const note = new URLSearchParams(location.search).get("note") || initialData.note || "No special requests";
  const emailOrPhone = new URLSearchParams(location.search).get("emailOrPhone") || initialData.emailOrPhone || "";


  const [currency, setCurrency] = useState("IDR"); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const exchangeRates = {
    IDR: 1,
    USD: 0.000066,
    AUD: 0.000097,
    EUR: 0.000059,
    GBP: 0.000051,
  };

  const calculatePriceInIDR = (distance: number, vehicleType: string) => {
    const basePrices: { [key: string]: number } = {
      standard: 44500,
      peopleCarrier: 80000,
      largePeopleCarrier: 150000,
      executive: 200000,
      van: 800000
    };
  
    let pricePerKm = 5000;
  
    if (distance < 10){
      pricePerKm = 30000;
    }
    else if (distance < 15){
      pricePerKm = 10500;
    }
    else if (distance > 15 && distance < 20){
      pricePerKm = 11500;
    }
    else if (distance >= 20 && distance < 30) {
      pricePerKm = 9500; // Tambahkan rentang jarak antara 20 dan 30 km
    }
    else if (distance > 75) {
      pricePerKm = 7000;
    } else if (distance > 50) {
      pricePerKm = 6000;
    }
  
    const basePrice = basePrices[vehicleType] || 0;
    const calculatedPrice = basePrice + (distance * pricePerKm);
  
    // Membulatkan harga ke bawah ke kelipatan 10.000
    return Math.floor(calculatedPrice / 10000) * 10000;
  };

  const convertPrice = (priceInIDR: number, currency: string) => {
    const convertedPrice = priceInIDR * exchangeRates[currency as keyof typeof exchangeRates];
    return currency === "IDR" ? convertedPrice : Math.ceil(convertedPrice); // Bulatkan ke atas jika bukan IDR
  };

  const distanceInKm = parseFloat(distance);

  const [journeys, setJourneys] = useState([
    {
      type: "Standard",
      priceInIDR: calculatePriceInIDR(distanceInKm, "standard"),
      icon: "/images/standard-car.png",
    description: "Up to 3 passengers, 3 suitcases",
      labels: ["Small"] // Tambahkan label
    },
    {
      type: "People carrier",
      priceInIDR: calculatePriceInIDR(distanceInKm, "peopleCarrier"),
      icon: "/images/people-carrier.png",
      description: "Up to 4 passengers, 4 suitcases",
      labels: ["More Bigger"] // Tambahkan label
    },
    {
      type: "Large people carrier",
      priceInIDR: calculatePriceInIDR(distanceInKm, "largePeopleCarrier"),
      icon: "/images/large-people-carrier.png",
      description: "Up to 4 passengers, 6 suitcases",
      labels: ["Most Recommended", "Fast Confirmation", "Best Option"]
    },
    {
      type: "Executive",
      priceInIDR: calculatePriceInIDR(distanceInKm, "executive"),
      icon: "/images/executive-car.png",
      description: "Up to 4 passengers, 8 suitcases, more comfort",
      labels: ["Luxury", "Premium"] // Tambahkan label
    },
    {
      type: "Van",
      priceInIDR: calculatePriceInIDR(distanceInKm, "van"),
      icon: "https://cdni.autocarindia.com/Utils/ImageResizer.ashx?n=https://cdni.autocarindia.com/ExtraImages/20210212120952_Hiace_front.jpg&w=700&c=1 ",
      description: "Up to 8 passengers, 8 suitcases, more comfort",
      labels: ["Group Travel", "Best for Families"] // Tambahkan label
    },
  ]);
  

  const handleChangeCurrency = (curr: string) => {
    setCurrency(curr);
    setIsDropdownOpen(false);

    // Recalculate prices for the new currency
    setJourneys(journeys.map(journey => ({
      ...journey,
      price: convertPrice(journey.priceInIDR, curr)
    })));
  };

  const handleChangeSearch = () => {
    navigate('/airport-transfer');
  };

  const handleChoose = (journey: any) => {
    const convertedPrice = convertPrice(journey.priceInIDR, currency);
  
    const checkoutData = {
      pickup: pickupAddress,
      dropoff: dropoffAddress,
      date,
      time,
      vehicleType: journey.type,
      priceInIDR: convertedPrice,
      currency,
      guests: adults,
      distance,
      note,
      seat,
      emailOrPhone
    };
  
    navigate("/airport-transfer/checkout", { state: checkoutData });
  };

  return (
    <div className="nc-SearchResultsPage overflow-hidden relative">
      <Helmet> 
        <title>BaliProGuide | Search Results</title>
      </Helmet>

      <BgGlassmorphism />
      <div className="container mx-auto p-4 relative">
        <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 border-b pb-4">
      <div>
        <div className="flex items-center space-x-2 mx-4">
          <span className="text-base md:text-lg font-semibold">{pickupAddress}</span>
          <ArrowsRightLeftIcon className="h-6 w-6 text-gray-500" />
          <span className="text-base md:text-lg font-semibold">{dropoffAddress}</span>
        </div>
        <div className="flex space-x-4 mt-2 items-center">
          <div className="flex items-center space-x-1">
            <CalendarIcon className="h-5 w-5" />
            <span className="text-sm md:text-base">{date}</span>
          </div>
          <div className="flex items-center space-x-1">
            <ClockIcon className="h-5 w-5" />
            <span className="text-sm md:text-base">{time}</span>
          </div>
          {roundTrip && (
            <div className="flex items-center space-x-1">
              <CheckCircleIcon className="h-5 w-5 text-green-500" />
              <span className="text-sm md:text-base">Round trip</span>
            </div>
          )}
          <div className="flex items-center space-x-1">
            <UserIcon className="h-5 w-5" />
            <span className="text-sm md:text-base">{adults}</span>
          </div>
          {distance && (
            <div className="flex items-center space-x-1 my-3 mx-2" >
              <MapIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm md:text-base">{`${distance}`}</span>
              <div className="ml-6 relative z-50"> {/* Tambah z-index agar dropdown tidak tenggelam */}
                <div className="relative inline-block text-left">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-xs md:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    id="currency-menu-button"
                    aria-expanded={isDropdownOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {currency.toUpperCase()}
                    <svg
                      className={`-mr-1 ml-2 h-5 w-5 transform ${isDropdownOpen ? 'rotate-0' : 'rotate-180'}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.832.445l4.5 6a1 1 0 01-1.664 1.11L10 5.24l-3.668 5.315a1 1 0 11-1.664-1.11l4.5-6A1 1 0 0110 3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {isDropdownOpen && (
                    <div
                      className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50" 
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="currency-menu-button"
                      tabIndex={-1}
                    >
                      <div className="py-1" role="none">
                        {Object.keys(exchangeRates).map((curr) => (
                          <button
                            key={curr}
                            onClick={() => handleChangeCurrency(curr)}
                            className="block px-4 py-2 text-xs md:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          >
                            {curr.toUpperCase()}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <button 
        className="bg-yellow-500 text-white py-2 px-4 text-xs md:text-sm rounded mt-4 md:mt-0"
        onClick={handleChangeSearch}
      >
        Change Search
      </button>
    </div>
          <div className="flex flex-col gap-4">
            {journeys.map((journey, index) => (
         <div 
         key={index} 
         className="relative border p-4 rounded-lg shadow hover:shadow-md transition flex flex-col sm:flex-row justify-between items-center overflow-hidden"
         style={{ height: 'auto', minHeight: '150px' }} 
       >
         {/* Bagian Kiri - Informasi Journey */}
         <div className="flex-1 relative">
           {/* Hanya tampilkan label jika journey memiliki properti labels */}
           {journey.labels && (
             <div className="flex space-x-2 mb-4 absolute top-0"> 
               {/* Iterasi melalui labels dan buat span untuk setiap label */}
               {journey.labels.map((label, index) => (
                 <span 
                   key={index} 
                   className={`px-2 py-1 text-white text-xs font-semibold rounded-md ${
                     label === "Most Recommended" ? "bg-yellow-500" : 
                     label === "Fast Confirmation" ? "bg-blue-500" : 
                     label === "Best Option" ? "bg-red-500" : 
                     "bg-gray-500" // Jika ada label lain, gunakan default warna abu-abu
                   }`}
                 >
                   {label}
                 </span>
               ))}
             </div>
           )}
       
           {/* Konten Utama */}
           <div className="mt-12"> {/* Memberikan jarak antara label dan konten */}
             <h3 className="text-lg font-semibold mb-2">{journey.type}</h3>
             <div className="text-gray-500 mb-2">{journey.description}</div>
             <div className="text-lg font-semibold mb-2">
               {`${currency.toUpperCase()} ${convertPrice(journey.priceInIDR, currency).toLocaleString("id-ID", {
                 minimumFractionDigits: 0
               })}`}
             </div>
             <div className="text-sm text-gray-500 mb-2">{`Distance: ${distance}`}</div> 
             <div className="flex items-center space-x-2 text-sm text-gray-500">
               <CheckCircleIcon className="h-5 w-5 text-green-500" />
               <span>Free cancellation</span>
               <CheckCircleIcon className="h-5 w-5 text-green-500" />
               <span>Meet and greet</span>
             </div>
           </div>
         </div>
       
         {/* Bagian Kanan - Gambar dan Tombol */}
         <div className="flex-shrink-0 overflow-hidden flex flex-col items-center sm:flex-row">
           <img 
             src={journey.icon} 
             alt={journey.type} 
             className="h-20 w-20 object-cover mb-2 sm:mb-0 sm:mr-2"
           />
           <button 
             className="bg-orange-500 text-white py-2 px-4 rounded sm:mt-0 mt-2"
             onClick={() => handleChoose(journey)}
           >
             Choose
           </button>
         </div>
       </div>
       
        
         
          
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPage;
