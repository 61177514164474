import http from '../utils/httpClient';

const cartService = {
  // Mendapatkan semua item di cart
  getItems: async () => {
    try {
      const response = await http.get('/carts'); // Menggunakan endpoint '/cart'
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch cart items');
    }
  },

  addItem: async (cartData) => {
    try {
      const response = await http.post('/carts', cartData); // Pastikan endpoint benar
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  

  // Menghapus item dari cart berdasarkan ID
  deleteItem: async (itemId) => {
    try {
      const response = await http.delete(`/carts/${itemId}`); // Menghapus berdasarkan ID
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to delete item from cart');
    }
  }
};

export default cartService;
