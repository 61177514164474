import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import blogService from "services/blogService";
import { PostDataType } from "data/types";

const BlogPage: FC = () => {
  const [posts, setPosts] = useState<PostDataType[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await blogService.getBlogs();
        console.log("Fetched Posts:", fetchedPosts); // Log data untuk debugging
        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Failed to fetch posts", error);
      }
    };
  
    fetchPosts();
  }, []);
  

  const MAGAZINE1_POSTS = posts.filter((_, i) => i >= 0 && i < 8);

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>BaliGuided | Blog</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 8 === */}
        <SectionLatestPosts posts={MAGAZINE1_POSTS} className="py-16 lg:py-28" />

        {/* === SECTION 1 === */}
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default BlogPage;
