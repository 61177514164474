import axios from "axios";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import authService from "services/authService";

function OauthSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const processOauth = async () => {
    const token = searchParams.get("token");
    if (!token) navigate("/login");

    const decoded = await authService.getDecodedToken(token);
    if (!decoded) navigate("/login");

    localStorage.setItem("authUser", token as string);
    navigate("/account");
  }

  useEffect(() => {
    processOauth();
  }, []);

  return (
    <div className="text-center text-3xl font-bold py-10">Oauth Success</div>
  );
}

export default OauthSuccess;
