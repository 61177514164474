import { DateRage } from "components/HeroSearchForm/type";

// Ubah tipe yang diterima menjadi satu tanggal atau null
const converSelectedDateToString = (selectedDate: Date | null): string => {
  // Jika tidak ada tanggal yang dipilih, return string kosong
  if (!selectedDate) return "";

  // Format tanggal sesuai yang diinginkan
  const dateString = selectedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
  });

  return dateString;
};

export default converSelectedDateToString;


