import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  useEffect(() => {
    // Load the Booking.com affiliate script
    const script1 = document.createElement("script");
    script1.src = "https://www.booking.com/affiliate/prelanding_sdk";
    script1.async = true;

    script1.onload = () => {
      // This function will run after the script is fully loaded
      const script2 = document.createElement("script");
      script2.innerHTML = `
        (function () {
          var BookingAffiliateWidget = new Booking.AffiliateWidget({
            "iframeSettings": {
              "selector": "bookingAffiliateWidget_a04b41d6-323b-41de-bb27-91b71a994139",
              "responsive": true
            },
            "widgetSettings": {
              "ss": "Ubud, Bali, Indonesia",
              "latitude": -8.50809,
              "longitude": 115.26396,
              "zoom": 9
            }
          });
        })();
      `;
      document.body.appendChild(script2);
    };

    document.body.appendChild(script1);

    // Cleanup function to remove scripts when component unmounts
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Book your stays in Bali with BaliProGuide</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative overflow-hidden">
        <div className="text-center py-24 lg:py-28">
          <div id="bookingAffiliateWidget_a04b41d6-323b-41de-bb27-91b71a994139"></div>
        </div>
      </div>
    </div>
  );
};

export default ListingStayPage;
