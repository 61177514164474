import React, { FC, Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DatePickerCustomDay from "../DatePicker/DatePickerCustomDay";

interface ModalSelectDateProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  onDateChange: (date: Date | null) => void; // Single date handler
}

const ModalSelectDate: FC<ModalSelectDateProps> = ({
  renderChildren,
  onDateChange,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Set default date to the current date when modal opens
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleChangeDate = (date: Date | null) => {
    setSelectedDate(date);
    onDateChange(date); // Pass the selected date to parent component
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              {`When's your trip?`}
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10">
                            <div className="overflow-hidden rounded-3xl">
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => handleChangeDate(date)}
                              showPopperArrow={false}
                              inline
                              minDate={new Date()} // Menetapkan hari ini sebagai tanggal minimum yang bisa dipilih
                              maxDate={new Date(new Date().getFullYear() + 1, 11, 31)} // Maksimum hingga Desember tahun depan
                              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                <div className="flex justify-between items-center px-4">
                                  <button
                                    onClick={decreaseMonth}
                                    className="text-sm focus:outline-none"
                                    style={{ marginRight: 'auto' }}
                                  >
                                    {"<"} Prev
                                  </button>
                                  <span className="text-sm font-medium text-center w-full">
                                    {date.toLocaleString("default", { month: "long", year: "numeric" })}
                                  </span>
                                  <button
                                    onClick={increaseMonth}
                                    className="text-sm focus:outline-none"
                                    style={{ marginLeft: 'auto' }}
                                  >
                                    Next {">"}
                                  </button>
                                </div>
                              )}
                              renderDayContents={(day, date) => (
                                <DatePickerCustomDay dayOfMonth={day} date={date} />
                              )}
                            />





                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {
                          handleChangeDate(null); // Clear the selected date
                        }}
                      >
                        Clear date
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Save
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectDate;
