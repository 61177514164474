import { create } from 'zustand';
import userService from 'services/userService';
import { useCallback } from 'react';

const useUserStore = create((set) => ({
  user: null, // Initial user state

  setUser: (userData) => set({ user: userData }),

  clearUser: () => set({ user: null }),

  fetchUser: async () => {
    try {
      const response = await userService.getMe(); // Replace with your API endpoint
      const userData = response;
      set({ user: userData });
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  },
}));

export const useUserStoreActions = () => {
  const { fetchUser, setUser, clearUser } = useUserStore();

  const memoizedFetchUser = useCallback(async () => {
    await fetchUser();
  }, [fetchUser]);

  return {
    fetchUser: memoizedFetchUser,
    setUser,
    clearUser,
  };
};

export default useUserStore;