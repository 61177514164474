import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

export const PHOTOS: string[] = [
  "https://baliwatersportspackage.com/images/ayungrafting/9.png",
  "https://baliwatersportspackage.com/images/ayungrafting/2.png",
  "https://baliwatersportspackage.com/images/ayungrafting/3.png",
  "https://baliwatersportspackage.com/images/ayungrafting/4.png",
  "https://baliwatersportspackage.com/images/ayungrafting/5.JPG",
  "https://baliwatersportspackage.com/images/ayungrafting/6.JPG",
  "https://baliwatersportspackage.com/images/ayungrafting/7.JPG",
  "https://baliwatersportspackage.com/images/ayungrafting/8.JPG"
];

export const includes_demo = [
  { name: "Safety-approved rafting equipment." },
  { name: "Professional river rafting guide." },
  { name: "Water resistant bags are provided for your valuable." },
  { name: "Locker, shower, towel, changing clothes room." },
  { name: "Lunch (meal)." },
  { name: "River rafting insurance coverage from 7 years old until 60 years old." },
];

export const imageGallery: ListingGalleryImage[] = [...PHOTOS].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);
