import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_LINK}/blog`;

const getAuthHeaders = () => {
  const token = localStorage.getItem('token'); 
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const blogService = {
  getBlogs: async () => {
    const response = await axios.get(API_BASE_URL);
    return response.data.payload; // Pastikan ini struktur yang benar
  },

  getBlogById: async (id) => {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data.payload;
  },

  getBlogBySlug: async (slug) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/slug/${slug}`);
      return response.data.payload;
    } catch (error) {
      console.error('Get blog by slug error:', error);
      throw new Error(error.message);
    }
  },

  createBlog: async (blogData) => {
    const response = await axios.post(API_BASE_URL, blogData, {
      ...getAuthHeaders(),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.payload;
  },

  updateBlog: async (id, blogData) => {
    const response = await axios.put(`${API_BASE_URL}/${id}`, blogData, {
      ...getAuthHeaders(),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.payload;
  },

  deleteBlog: async (id) => {
    const response = await axios.delete(`${API_BASE_URL}/${id}`, getAuthHeaders());
    return response.data.payload;
  },
};

export default blogService;
