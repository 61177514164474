import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC, useState } from "react";

export interface FiveStartIconForRateProps {
  className?: string;
  iconClass?: string;
  rating: number;
  onRating: (rating: number) => void;
}

const FiveStartIconForRate: FC<FiveStartIconForRateProps> = ({
  className = "",
  iconClass = "w-4 h-4",
  rating,
  onRating,
}) => {
  const [currentHover, setCurrentHover] = useState(0);

  return (
    <div
      className={`nc-FiveStartIconForRate flex items-center text-neutral-300 ${className}`}
      data-nc-id="FiveStartIconForRate"
    >
      {[1, 2, 3, 4, 5].map((item) => (
        <StarIcon
          key={item}
          className={`${
            rating >= item || currentHover >= item ? "text-yellow-500" : ""
          } ${iconClass}`}
          onMouseEnter={() => setCurrentHover(item)}
          onMouseLeave={() => setCurrentHover(0)}
          onClick={() => onRating(item)}
        />
      ))}
    </div>
  );
};

export default FiveStartIconForRate;
