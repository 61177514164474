import React from "react";
import SectionGridFilterCard from "containers/ListingExperiencesPage/SectionGridFilterCard";
import SectionHero from "components/SectionHero/SectionHero";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionGridFilterCard       
          className="PageHome_s1"
        />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <SectionSubscribe2 />

      </div>
    </div>
  );
}

export default PageHome;
