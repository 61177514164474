import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import ButtonSubmit from "../ButtonSubmit";
import { useNavigate } from "react-router-dom";

export interface ExperiencesSearchFormProps {}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = () => {
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`/experiences/search?query=${encodeURIComponent(location)}`);
  };

  return (
    <form
      className="w-full relative mt-8 flex flex-col md:flex-row rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
      onSubmit={handleSearch}
    >
      <LocationInput
        className="flex-[1.5]"
        onChange={(value) => setLocation(value)} 
      />
      <div className="flex items-center">
        <ButtonSubmit className="h-full w-14 md:w-16 md:h-full" />
      </div>
    </form>
  );
};

export default ExperiencesSearchForm;
