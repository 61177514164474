import httpClient from '../utils/httpClient';

const experienceService = {
  getExperiences: async () => {
    try {
      const response = await httpClient.get('/experiences');
      return response.data.payload;
    } catch (error) {
      console.error('Get experiences error:', error);
      throw new Error(error.message);
    }
  },
  getExperiencesPagination: async (page = 1) => { // default page ke 1 jika tidak diberikan
    try {
      const response = await httpClient.get(`/experiences/pagination?page=${page}`);
      console.log(response); // Log response untuk melihat apakah reviews disertakan
      return response.data;
    } catch (error) {
      console.error('Get experiences error:', error);
      throw new Error(error.message);
    }
  },

 // Fungsi search baru
 searchExperiences: async (searchQuery) => {
  try {
    const response = await httpClient.get(`/experiences/search?query=${encodeURIComponent(searchQuery)}`);
    return response.data;
  } catch (error) {
    console.error("Error searching experiences:", error);
    throw new Error(error.message);
  }
},


  

  getExperienceBySlug: async (slug) => {
    const response = await httpClient.get(`/experiences/details/${slug}`);
    return response.data;
  },


  // Fungsi untuk konfirmasi booking
 confirmBooking: async (cartData) => {
  try {
    const response = await httpClient.post('/experiences/send-booking-email', cartData);
    return response.data;
  } catch (error) {
    console.error('Confirm booking error:', error);
    throw new Error(error.message);
  }
}

};

 
export default experienceService;
