import React, { FC, useState } from "react";
import authService from "services/authService";
import Input from "shared/Input/Input";
import { Alert } from "shared/Peringatan/Peringatan";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const VerifEmailMessage: FC = () => {
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("")
    const [typeMessage, setTypeMessage] = useState<"success" | "error" | "default" | "warning" | "info">("default");
    
    const handleSendEmail = async () => {
        try {
        const response = await authService.resendVerificationEmail({ email });
        setMessage(response.message);
        setTypeMessage('success')
        } catch (error: any) {
        setMessage(error.message)
        setTypeMessage('error')
        }
    };
    
    return (
        <div className="flex flex-col items-center justify-center my-14">
        <div className="text-center">
            <h2 className="text-3xl font-semibold">Check your email for Verification</h2>
        </div>
        <div className="mt-20 w-full md:w-[500px] text-center">
            <p className="mb-2">Resend email verification</p>
            <Input
            placeholder="your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <div className="mt-8">
            <ButtonPrimary
            onClick={handleSendEmail}
            type="button"
            >
            Resend 
            </ButtonPrimary>
        </div>
        </div>
    );
    }

export default VerifEmailMessage;