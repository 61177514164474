import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import Avatar from "shared/Avatar/Avatar";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import userService from "services/userService";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [authUser, setAuthUser] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Periksa token di localStorage
      if (token) {
        setAuthUser(token);
        try {
          const user = await userService.getMe(); // Panggil getMe jika token ada
          setProfileImage(user?.profile_picture?.path ?? null);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            {authUser ? (
              <div
                onClick={(e) => { setShowDropdown(!showDropdown); }}
                className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-200 dark:bg-neutral-800 text-neutral-700 dark:text-neutral-100"
              >
                <span className="sr-only">Profile</span>
                <Avatar
                  sizeClass="w-full h-full"
                  imgUrl={profileImage || undefined}
                />
              </div>
            ) : (
              <ButtonPrimary href="/login">Login</ButtonPrimary>
            )}
            {showDropdown && (
              <div className="absolute top-16 right-0 z-10 bg-white dark:bg-neutral-800 rounded-md shadow-lg w-48">
                <Link
                  to="/account"
                  className="block px-4 py-2 text-neutral-900 dark:text-neutral-100 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                >
                  Account
                </Link>
                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    window.location.reload();
                  }}
                  className="block px-4 py-2 text-red-900 dark:text-red-300 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
