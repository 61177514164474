import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
  close?: () => void;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  close = () => {},
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-neutral-900";
      break;
    case "info":
      classes += " bg-blue-200 text-blue-600";
      break;
    case "success":
      classes += " bg-green-200 text-green-600";
      break;
    case "error":
      classes += " bg-red-200 text-red-600";
      break;
    case "warning":
      classes += " bg-yellow-200 text-yellow-600";
      break;
    default:
      break;
  }
  

  return (
    <div
      className={`max-w-[320px] md:max-w-[600px] left-[50%] -translate-x-[50%] ttnc-alert fixed z-[999] top-[10vh] transition-all flex items-center text-sm px-6 pl-14 pt-4 pb-3 rounded-lg ${classes}`}
    >
      {children}
      <ButtonClose className="absolute top-[10px] left-4" onClick={close}/>
    </div>
  );
};
