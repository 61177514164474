import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image:
      "https://storage.googleapis.com/media_bali_tour/daytours.jpg",
    title: "Day Tours",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "http://localhost:3000/experiences/search?query=tour",
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://storage.googleapis.com/media_bali_tour/ayungrafting.jpg",
    title: "Water Activities",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "http://localhost:3000/experiences/search?query=water",
      name: i.AppName,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://storage.googleapis.com/media_bali_tour/seminyakhorseriding.jpg",
    title: "Animal Riding",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "http://localhost:3000/experiences/search?query=ride",
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://storage.googleapis.com/media_bali_tour/2022-09-10%20(1).jpg",
    title: "Adventure",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "http://localhost:3000/experiences/search?query=adventure",
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://storage.googleapis.com/media_bali_tour/avanza.png",
    title: "Private Car Charter",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "http://localhost:3000/experiences/search?query=car",
      name: i.Country,
    })),
  },
];


// Other Pages
const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
];


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/experiences",
    name: "Experience",
    type: "megaMenu",
    isNew: true,
    megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/airport-transfer",
    name: "Airport Transfer",
    type: "none",
    isNew: true
  },
  {
    id: ncNanoId(),
    href: "/hotel-transfer",
    name: "Hotel Transfer",
    type: "none",
    isNew: true
  },
  {
    id: ncNanoId(),
    href: "/accomodation",
    name: "Stays",
    type: "none",
    isNew: true,
    megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Other pages",
    type: "dropdown",
    children: otherPageChildMenus,
  },
];
