import Label from "components/Label/Label";
import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import useUserStore, { useUserStoreActions } from "store/userStore";
import authService from "services/authService";
import { Alert } from "shared/Peringatan/Peringatan";

const AccountPass = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [provider, setProvider] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [loadingPassword, setLoadingPassword] = useState<boolean>(false);
  const [typeMessage, setTypeMessage] = useState<
    "success" | "error" | "default" | "warning" | "info"
  >("default");

  const user = useUserStore((state) => state.user);
  const { fetchUser } = useUserStoreActions();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    setCurrentEmail(user?.email);
    setProvider(user?.provider);
  }, [user]);

  const handleUpdatePassword = async () => {
    if(provider !== "local") {
      setTypeMessage("error");
      setMessage("This feature is only available for non-oauth account");
      return;
    }
    
    try {
      setLoadingPassword(true);
      await authService.updatePassword({
        old_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      });

      setLoadingPassword(false);
      setTypeMessage("success");
      setMessage("Password updated successfully");

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error: any) {
      setLoadingPassword(false);
      console.log(error);
      setTypeMessage("error");
      setMessage(error.message);
    }
  };

  const handleChangeEmail = async () => {
    if(provider !== "local") {
      setTypeMessage("error");
      setMessage("This feature is only available for non-oauth account");
      return;
    }
    
    try {
      setLoadingEmail(true);
      const response = await authService.changeEmail({
        email: newEmail,
      });

      setLoadingEmail(false);
      setTypeMessage("success");
      setMessage(response.message);

      setNewEmail("");
    } catch (error: any) {
      setLoadingEmail(false);
      console.log(error);
      setTypeMessage("error");
      setMessage(error.message);
    }
  };

  return (
    <div>
      {message && (
        <Alert type={typeMessage} close={() => setMessage("")}>
          {message}
        </Alert>
      )}

      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update Password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Old password</Label>
              <Input
                type="password"
                className={`${
                  provider !== "local" ? "cursor-not-allowed " : ""
                }mt-1.5`}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                disabled={provider !== "local"}
              />
            </div>
            <div>
              <Label>New password</Label>
              <Input
                type="password"
                className={`${
                  provider !== "local" ? "cursor-not-allowed " : ""
                }mt-1.5`}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={provider !== "local"}
              />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input
                type="password"
                className={`${
                  provider !== "local" ? "cursor-not-allowed " : ""
                }mt-1.5`}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={provider !== "local"}
              />
            </div>
            <div className="pt-2">
              <ButtonPrimary
                onClick={handleUpdatePassword}
                disabled={loadingPassword}
              >
                {
                  // create spinner animation while loading
                  loadingPassword ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                    </div>
                  ) : (
                    "Update Password?"
                  )
                }
              </ButtonPrimary>
            </div>
          </div>
        </div>

        <div className="space-y-6 sm:space-y-8 mt-24">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Change Email</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current email</Label>
              <Input
                type="email"
                className="mt-1.5 cursor-not-allowed"
                disabled
                value={currentEmail}
              />
            </div>
            <div>
              <Label>New email</Label>
              <Input
                type="email"
                className={`${
                  provider !== "local" ? "cursor-not-allowed " : ""
                }mt-1.5`}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                disabled={provider !== "local"}
              />
            </div>
            <div className="pt-2">
              <ButtonPrimary
                onClick={handleChangeEmail}
                disabled={loadingEmail}
              >
                {
                  // create spinner animation while loading
                  loadingEmail ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                    </div>
                  ) : (
                    "Send Change Email Link"
                  )
                }
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
