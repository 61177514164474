import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "shared/Peringatan/Peringatan";
import Oauth from "helpers";

import authService from "services/authService";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = Oauth.config


const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [full_name, setFullName] = useState<string>("")
  const [confirm_password, setConfirmPassword] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [typeMessage, setTypeMessage] = useState<"success" | "error" | "default" | "warning" | "info">("default");
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const handleSignUp = async () => {


    try {
      setLoading(true)
      const response = await authService.register({full_name,email, password, confirm_password})
      setTypeMessage("success");
      setMessage(response.message);
      setLoading(false)
      navigate('/verif-email');
    } catch (error: any) {
      setLoading(false)
      setTypeMessage("error")
      setMessage(error.message)
    }
    
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up BaliGuided</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              item.isUsed && <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" >
            {message && <Alert type={typeMessage} close={() => setMessage("")}>{message}</Alert>}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Full name
              </span>
              <Input
                type="email"
                placeholder="your name"
                className="mt-1"
                value={full_name}
                onInput={(e) => {
                  setFullName(e.currentTarget.value)
                }}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={email}
                onInput={(e) => {
                  setEmail(e.currentTarget.value)
                }}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" placeholder="your password" required value={password} onInput={
                (e) => {
                  setPassword(e.currentTarget.value)
                }
              }/>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" className="mt-1" placeholder="confirm your password" required value={confirm_password} onInput={
                (e) => {
                  setConfirmPassword(e.currentTarget.value)
                }
              }/>
            </label>
            <ButtonPrimary type="button" onClick={handleSignUp} disabled={loading}>
             {
                loading ? <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                </div> : "Sign Up"

             }
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
