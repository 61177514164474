import React, { FC } from 'react';

const TermsCondition: FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-semibold mb-6">Terms and Conditions</h1>
      <div className="space-y-4 text-gray-700 dark:text-gray-300">
        <p>
          <strong>1. Payment Methods:</strong> Payment for airport transfer services can be made using the accepted payment methods specified by the service provider, including but not limited to credit cards, debit cards, and electronic funds transfers.
        </p>
        <p>
          <strong>2. Payment Authorization:</strong> By providing payment information, you authorize the service provider to charge the agreed-upon amount for the airport transfer service.
        </p>
        <p>
          <strong>3. Inclusive Pricing:</strong> The price includes parking fees and highway fees (if any).
        </p>
        <p>
          <strong>4. Full Payment Required:</strong> Full payment in advance is required to proceed with the reservation.
        </p>
        <p>
          <strong>5. Non-Refundable Payments:</strong> All payments received are non-refundable and non-transferable.
        </p>
        <p>
          <strong>6. Cancellation Policy:</strong> Any cancellation within 48 hours prior to the event will be charged 100% to the guest.
        </p>
        <p>
          <strong>7. Schedule Adjustment:</strong> Schedule adjustments are allowed up to 72 hours prior to the event and must receive confirmation from Iris Bali. Any revised last-minute flight schedule from the airline must also be communicated and receive confirmation from Iris Bali.
        </p>
        <p>
          <strong>8. Rescheduling Policy:</strong> Full payment received with any rescheduled date can be accommodated within a maximum of 1 (one) year from the payment received. Otherwise, the payment will be forfeited.
        </p>
        <p>
          <strong>9. Airport Pickup:</strong> For airport pickup services, our driver will be waiting at the arrival terminal gate paging the guest’s name, with a maximum 3-hour waiting period.
        </p>
        <p>
          <strong>10. Reservation Recommendations:</strong> To avoid possible disappointment, we recommend making reservations by WhatsApp at +62 821-387-53-576.
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
