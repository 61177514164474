import __stayListing from "./jsons/__stayListing.json";
import __experiencesListing from "./jsons/__experiencesListing.json";
import {
  DEMO_STAY_CATEGORIES,
  DEMO_EXPERIENCES_CATEGORIES,
} from "./taxonomies";
import { ExperiencesDataType, StayDataType } from "./types";
import { DEMO_AUTHORS } from "./authors";

// Mapping for stay listings
const DEMO_STAY_LISTINGS = __stayListing.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

// // Mapping for experience listings
// const DEMO_EXPERIENCES_LISTINGS = __experiencesListing.map(
//   (post, index): ExperiencesDataType => {
//     //  ##########  GET CATEGORY BY CAT ID ######## //
//     const category = DEMO_EXPERIENCES_CATEGORIES.filter(
//       (taxonomy) => taxonomy.id === post.listingCategoryId
//     )[0];

//     // Add experience_medias property if it does not exist
//     const experienceMedias = post.experience_medias || [];

//     return {
//       ...post,
//       id: `experiencesListing_${index}_`,
//       saleOff: !index ? "-20% today" : post.saleOff,
//       isAds: !index ? true : post.isAds,
//       author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
//       listingCategory: category,
//       experience_medias: experienceMedias,
//     };
//   }
// );

export { DEMO_STAY_LISTINGS };
