import React, { useState, useEffect } from "react";
import ModalSelectDate from "components/SearchSelect/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import NcModal from "../../../shared/NcModal/NcModal";

interface MobileFooterStickyProps {
  experience: {
    id: string;
    title: string;
    location: string;
    price: number;
    experience_pickups?: { pickup_area: string; price: number }[];
    imageUrl: string;
    experience_type_id: number;
    experience_medias?: { media: { path: string } }[];
    max_participant_count: number;
    times_available_string?: string;
    has_child_price?: boolean;
    child_price?: number;
  };
  currency: string;
  guests: number;
  convertedPrice: number;
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onGuestsChange: (guests: number) => void;
  selectedTime: string;
  onTimeChange: (time: string) => void;
}

const MobileFooterSticky: React.FC<MobileFooterStickyProps> = ({
  experience,
  currency,
  guests,
  convertedPrice,
  selectedDate,
  selectedTime,
  onDateChange,
  onGuestsChange,
  onTimeChange,
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [adultGuests, setAdultGuests] = useState(1);
  const [childGuests, setChildGuests] = useState(0);
  const [infantGuests, setInfantGuests] = useState(0);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isMobile) return null;

  const adultPrice = experience.price;
  const childPrice = experience.has_child_price ? experience.child_price || 0 : adultPrice;

  const totalAdultPrice = adultPrice * adultGuests;
  const totalChildPrice = childPrice * childGuests;
  const totalPrice = totalAdultPrice + totalChildPrice;

  const formatPrice = (price: number, currency: string) => {
    return currency === "IDR" ? price.toLocaleString("id-ID") : price.toFixed(2);
  };

  const handleReserveClick = () => {
    if (!selectedDate) {
      alert("Please select a date before proceeding.");
      return;
    }

    if (!selectedTime) {
      alert("Please select a time before proceeding.");
      return;
    }

    const checkoutData = {
      experienceId: experience.id,
      experiencePickup: experience.experience_pickups,
      experienceTitle: experience.title,
      experienceLocation: experience.location,
      price: totalPrice, // total calculated price
      adultPrice, // harga per dewasa
      childPrice, // harga per anak
      totalAdultPrice, // total harga dewasa
      totalChildPrice, // total harga anak
      adultGuests, // jumlah tamu dewasa
      childGuests, // jumlah tamu anak-anak
      infantGuests, // jumlah tamu bayi
      date: selectedDate,
      time: selectedTime,
      mainImage: experience.experience_medias?.[0]?.media?.path || experience.imageUrl,
      currency,
      has_child_price: experience.has_child_price,
    };

    sessionStorage.setItem("checkoutData", JSON.stringify(checkoutData));
    navigate(`/checkout`);
  };

  return (
    <div className="fixed bottom-0 inset-x-0 py-3 bg-white border-t z-40">
      <div className="container flex items-center justify-between">
        <div>
          <span className="block text-xl font-semibold">
            {currency} {formatPrice(totalPrice, currency)}
            <span className="ml-1 text-sm font-normal">
              {experience.experience_type_id === 1 ? "/Person" : "/Group"}
            </span>
          </span>
          <div className="flex items-center space-x-4">
            <ModalSelectDate
              onDateChange={onDateChange}
              renderChildren={({ openModal }) => (
                <span onClick={openModal} className="block text-sm underline font-medium">
                  {converSelectedDateToString(selectedDate) || "Select date"}
                </span>
              )}
            />

            <select
              className="border rounded-lg px-4 py-2"
              onChange={(e) => onTimeChange(e.target.value)}
              value={selectedTime}
            >
              <option value="" disabled>
                Select time
              </option>
              {experience.times_available_string?.split("__").map((time: string) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>

            <button
              onClick={() => setIsGuestModalOpen(true)}
              className="underline text-sm font-medium"
            >
              Select Guests
            </button>
          </div>
        </div>

        <ButtonPrimary onClick={handleReserveClick}>Reserve</ButtonPrimary>
      </div>

      <NcModal
    isOpenProp={isGuestModalOpen}
    onCloseModal={() => setIsGuestModalOpen(false)}
    modalTitle="Select Guests"
    renderContent={() => (
      <div className="flex flex-col space-y-4">
        {/* Adults */}
        <div className="flex items-center justify-between">
          <span>{experience.has_child_price ? "Adults" : "Person"}</span>
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => setAdultGuests(Math.max(adultGuests - 1, 1))}
              className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
            >
              -
            </button>
            <span className="text-lg font-medium">{adultGuests}</span>
            <button 
              onClick={() => setAdultGuests(adultGuests + 1)}
              className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
            >
              +
            </button>
          </div>
        </div>

        {/* Children */}
        {experience.has_child_price && (
          <div className="flex items-center justify-between">
            <span>Children</span>
            <div className="flex items-center space-x-2">
              <button 
                onClick={() => setChildGuests(Math.max(childGuests - 1, 0))}
                className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
              >
                -
              </button>
              <span className="text-lg font-medium">{childGuests}</span>
              <button 
                onClick={() => setChildGuests(childGuests + 1)}
                className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
              >
                +
              </button>
            </div>
          </div>
        )}

        {/* Infants */}
        {experience.has_child_price && (
          <div className="flex items-center justify-between">
            <span>Infants (free)</span>
            <div className="flex items-center space-x-2">
              <button 
                onClick={() => setInfantGuests(Math.max(infantGuests - 1, 0))}
                className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
              >
                -
              </button>
              <span className="text-lg font-medium">{infantGuests}</span>
              <button 
                onClick={() => setInfantGuests(infantGuests + 1)}
                className="px-3 py-2 text-lg font-semibold bg-gray-200 rounded-lg"
              >
                +
              </button>
            </div>
          </div>
        )}

        <ButtonPrimary onClick={() => setIsGuestModalOpen(false)}>Done</ButtonPrimary>
      </div>
    )}
  />

    </div>
  );
};

export default MobileFooterSticky;
