import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, Navigate } from "react-router-dom";
import { Alert } from "shared/Peringatan/Peringatan";

const VerifEmailSuccess: FC = () => {
    const [message, setMessage] = useState<string>("");
    const [typeMessage, setTypeMessage] = useState<
        "success" | "error" | "default" | "warning" | "info"
    >("default");
    
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-neutral-100">
        <div className="max-w-md w-full space-y-8">
        {message && <Alert type={typeMessage} close={() => setMessage("")}>{message}</Alert>}
            <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-semibold text-neutral-900">
                Verifikasi Email Berhasil
            </h1>
            <p className="text-lg text-neutral-500 text-center mt-4">
                Terima kasih telah melakukan verifikasi email. Silahkan login untuk
                melanjutkan.
            </p>
            </div>
            <div className="flex flex-col items-center justify-center">
            <Link to="/login">
                <ButtonPrimary className="w-full">Login</ButtonPrimary>
            </Link>
            </div>
        </div>
        </div>
    );
    };

export default VerifEmailSuccess;