import React, { FC } from "react";
import { PostDataType } from "data/types";
import Card12 from "containers/BlogPage/Card12";

export interface SectionLatestPostsProps {
  posts: PostDataType[];
  className?: string;
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({ posts, className = "" }) => {
  // Pemetaan data untuk memastikan `desc` memiliki nilai
  const postsWithDesc = posts.map((post) => ({
    ...post,
    desc: post.desc || post.description, // Gunakan `description` jika `desc` tidak ada
  }));

  return (
    <div className={`nc-SectionLatestPosts ${className}`}>
      {postsWithDesc.map((post, index) => (
        <Card12 key={index} post={post} className="mb-8 last:mb-0" />
      ))}
    </div>
  );
};

export default SectionLatestPosts;
