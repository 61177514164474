import React, { Fragment, FC, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface GuestsInputProps {
  className?: string;
  onGuestsChange: (totalGuests: number, adultGuests: number, childGuests: number) => void;
  maxParticipantCount: number; // New prop for max participant count
  hasChildPrice?: boolean; // New prop to indicate if child pricing is available
  childAgeRange: { min: number; max: number }; // New prop for child age range
  adultAgeRange: { min: number; max: number }; // New prop for adult age range
}

const GuestsInput: FC<GuestsInputProps> = ({ 
  className = "flex-1", 
  onGuestsChange, 
  maxParticipantCount, 
  hasChildPrice, 
  childAgeRange, 
  adultAgeRange 
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);

  useEffect(() => {
    const totalGuests = guestAdultsInputValue + guestChildrenInputValue + guestInfantsInputValue;
    onGuestsChange(totalGuests, guestAdultsInputValue, guestChildrenInputValue); // Call the callback with the total guests count
  }, [guestAdultsInputValue, guestChildrenInputValue, guestInfantsInputValue, onGuestsChange]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
    }
  };

  const totalGuests = guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${open ? "shadow-lg" : ""}`}>
            <Popover.Button className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}>
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={maxParticipantCount - guestChildrenInputValue} // Ensure adults and children combined don't exceed max participants
                min={1}
                label="Adults"
                desc={`Ages ${adultAgeRange.min} or above`} // Use dynamic age range for adults
              />

              {hasChildPrice && (
                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrenInputValue}
                  onChange={(value) => handleChangeData(value, "guestChildren")}
                  max={maxParticipantCount - guestAdultsInputValue} // Ensure adults and children combined don't exceed max participants
                  min={0}
                  label="Children"
                  desc={`Ages ${childAgeRange.min} - ${childAgeRange.max}`} // Use dynamic age range for children
                />
              )}

          <NcInputNumber
            className="w-full mt-6"
            defaultValue={guestInfantsInputValue}
            onChange={(value) => handleChangeData(value, "guestInfants")}
            max={maxParticipantCount} // No specific limit for infants
            min={0}
            label="Infants"
            desc="Free, sit on lap"
          />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
