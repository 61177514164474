import React, { FC } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";


export interface AdminPageProps {
  className?: string;
}

const AdminPage: FC<AdminPageProps> = ({ className = "" }) => {
  return (
    <div className={className}>
    <div className="container mb-24 lg:mb-32" />
    <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
    Login Admin
    </h2>
      <div className="max-w-md mx-auto space-y-6 mb-20">
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder=""
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" />
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
        </form>
      </div>
    </div>
  );
};

export default AdminPage;
